// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;

}

*:focus{
  text-decoration: none;
  outline:none
}

.App{
  min-height: 100vh;
  /* font-family: 'Roboto medium'; */
}




`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";;AAEA;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;;AAEX;;AAEA;EACE,qBAAqB;EACrB;AACF;;AAEA;EACE,iBAAiB;EACjB,kCAAkC;AACpC","sourcesContent":["\n\n*{\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n\n}\n\n*:focus{\n  text-decoration: none;\n  outline:none\n}\n\n.App{\n  min-height: 100vh;\n  /* font-family: 'Roboto medium'; */\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

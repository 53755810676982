// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.crPasw_main__a\\+Wft{
    display: flex;
    flex-direction: column;
    max-width: 360px;
    align-items: center;
    text-align: center;
}
h2{
    font-size: 24px;
}
.crPasw_text__hew9\\+{
    margin-top: 36px;
}
.crPasw_form__LHSpI{
    margin-top: 36px;
    height: 102px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.crPasw_error__0OYtP{
    margin-top: 24px;
    margin-bottom: 24px;
    color:red;
    cursor:pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/modules/Registration/Components/RegCreatePasswordForm/crPasw.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,cAAc;AAClB","sourcesContent":["\n.main{\n    display: flex;\n    flex-direction: column;\n    max-width: 360px;\n    align-items: center;\n    text-align: center;\n}\nh2{\n    font-size: 24px;\n}\n.text{\n    margin-top: 36px;\n}\n.form{\n    margin-top: 36px;\n    height: 102px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n.error{\n    margin-top: 24px;\n    margin-bottom: 24px;\n    color:red;\n    cursor:pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `crPasw_main__a+Wft`,
	"text": `crPasw_text__hew9+`,
	"form": `crPasw_form__LHSpI`,
	"error": `crPasw_error__0OYtP`
};
export default ___CSS_LOADER_EXPORT___;

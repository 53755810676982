import { Box, Card, CardMedia, Paper, Table, TableCell, TableContainer, TableRow } from "@mui/material"
import { styleCard } from '../styles/styles';
import serviceDefaultPic from '../../../assets/Pic.png';
import { TimeProgressBar } from "./TimeProgressBar";
import { IDriver } from "../types";
import FsLightbox from "fslightbox-react";
import { useEffect, useState } from "react";
import { $file } from "../../../api";
import { downloadFile } from "../../Reports/drawers/ReportCard";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import { IncreasePhotoIcon } from "../assets/IncreasePhotoIcon";
interface IDriverDataProps {
    data?: IDriver | null
}

export const MainDataCard = ({ data }: IDriverDataProps) => {

    const [toggler, setToggler] = useState(false);
    const [count, setCount] = useState(0)
    const [imageUrl, setImageUrl] = useState<string>('')
    const dataContainerStyle = {
        backgroundColor: '#FFFFFF',
        border: '1px solid #EEEEEE',
        borderRadius: '4px',
    }


    useEffect(() => {
        if (data?.photo) {

          !!data?.photo ?  setImageUrl(data.photo[0]) : setImageUrl(serviceDefaultPic) 
        }

    }, [data])

    const curImage = <img src={imageUrl}></img>


    const containerHeadingStyle = {
        backgroundColor: '#EEEEEE',
        padding: '4px 12px',
        fontWeight: '500',
        fontSize: '14px',
        color: '#565656',
        lineHeight: '18.2px'
    }

    const containerDataStyle = {
        padding: '4px 12px',
        fontWeight: '400',
        fontSize: '16px',
        color: '#2A2A2A',
        lineHeight: '18.2px'
    }
    return (
       

        <Box sx={{
            backgroundColor: 'white',
            fontFamily: 'Roboto',
            borderRadius: '6px',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            height: '100%',
        }}>

            {/* <h3 style={{
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '26px',
                color: '#3E3E3E'
            }}>
                Информация о водителе
            </h3> */}

            <Box sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: '16px',
            }}>
                <Box sx={[dataContainerStyle, { overflow: 'hidden', minWidth: '201px', maxHeight: '272px', margin: '0 auto', position: 'relative'  }]}>
                    <Box sx={containerHeadingStyle}>
                        Эталонное фото
                    </Box>
                    <CardMedia sx={{ minWidth: '201px', minHeight: '248px', cursor: 'pointer' }} image={!!data?.photo ? data.photo : serviceDefaultPic}
                        onClick={() => { setToggler(!toggler) }}
                    />
                    {/* <img style={{ width: '178px', height: '238px', cursor: 'pointer' }} src={!!data?.photo ? data.photo : serviceDefaultPic}></img> */}
                        <Box 
                            sx={{
                                position: 'absolute', right: '0px', bottom: '0px', zIndex: '2', pointerEvents: 'none',
                                backgroundColor: 'rgb(0,0,0,0.5)', padding: '4px', borderRadius: '4px', 
                                width: '32px', height: '32px',
                            }}>
                            <IncreasePhotoIcon />
                        </Box>
                    <FsLightbox
                        toggler={toggler}

                        key={count}
                        sources={!!imageUrl ? [curImage] : [serviceDefaultPic]}
                    />

                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    width: '100%',
                }}>

                    <Box sx={dataContainerStyle}>
                        <Box sx={containerHeadingStyle}>
                            Ф.И.О. Водителя
                        </Box>
                        <Box sx={containerDataStyle}>
                            {
                                (data?.lastname || '-') + ' ' +
                                (data?.firstname || '-') + ' ' +
                                (data?.middlename || '-')
                            }
                        </Box>
                    </Box>

                    <Box sx={dataContainerStyle}>
                        <Box sx={containerHeadingStyle}>
                            Возраст
                        </Box>
                        <Box sx={containerDataStyle}>
                            {data?.age || '-'}
                        </Box>
                    </Box>

                    <Box sx={dataContainerStyle}>
                        <Box sx={containerHeadingStyle}>
                            Компания
                        </Box>
                        <Box sx={containerDataStyle}>
                            {data?.company || '-'}
                        </Box>
                    </Box>

                </Box>
            </Box>
        </Box>

    )
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pinCode_main__gzQOo{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;

    text-align: center;
}
.pinCode_text__T62k7{
    margin-top: 30px;
margin-bottom: 36px;
}
.pinCode_error__Rzi6I{
    margin-top: 22px;
    cursor:pointer;
    color:red;
}
.pinCode_linkCont__y7mjH{
    margin-top: 22px;
    margin-bottom: 22px;
}
.pinCode_checkboxCont__YPypv{
    margin-top: 62px;
    display: flex;
    align-items: baseline;
    width: 100%;
}
.pinCode_codeLink__4ZcXX{
    /*margin-top: 22px;*/
    /*margin-bottom: 22px;*/
}
.pinCode_agreementCont__s3xKT{
    text-align: left;
    margin-left: 15px;
}
.pinCode_agreementLinks__qPpEt{
    display: flex;
    flex-direction: column;
    margin-top: 28px;
}
.pinCode_agreementLink__nzWRC{
    color: #757575;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/modules/Recovery/components/RecPinCodeForm/pinCode.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;;IAEhB,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB,mBAAmB;AACnB;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,qBAAqB;IACrB,WAAW;AACf;AACA;IACI,oBAAoB;IACpB,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":["\n.main{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-width: 360px;\n\n    text-align: center;\n}\n.text{\n    margin-top: 30px;\nmargin-bottom: 36px;\n}\n.error{\n    margin-top: 22px;\n    cursor:pointer;\n    color:red;\n}\n.linkCont{\n    margin-top: 22px;\n    margin-bottom: 22px;\n}\n.checkboxCont{\n    margin-top: 62px;\n    display: flex;\n    align-items: baseline;\n    width: 100%;\n}\n.codeLink{\n    /*margin-top: 22px;*/\n    /*margin-bottom: 22px;*/\n}\n.agreementCont{\n    text-align: left;\n    margin-left: 15px;\n}\n.agreementLinks{\n    display: flex;\n    flex-direction: column;\n    margin-top: 28px;\n}\n.agreementLink{\n    color: #757575;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `pinCode_main__gzQOo`,
	"text": `pinCode_text__T62k7`,
	"error": `pinCode_error__Rzi6I`,
	"linkCont": `pinCode_linkCont__y7mjH`,
	"checkboxCont": `pinCode_checkboxCont__YPypv`,
	"codeLink": `pinCode_codeLink__4ZcXX`,
	"agreementCont": `pinCode_agreementCont__s3xKT`,
	"agreementLinks": `pinCode_agreementLinks__qPpEt`,
	"agreementLink": `pinCode_agreementLink__nzWRC`
};
export default ___CSS_LOADER_EXPORT___;

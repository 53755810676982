// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_error__lHmq9{
    margin-top: 24px;
    color:red;
    margin-bottom:24px;
    cursor:pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/modules/Registration/Components/RegPhoneMailForm/form.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,SAAS;IACT,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".error{\n    margin-top: 24px;\n    color:red;\n    margin-bottom:24px;\n    cursor:pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `form_error__lHmq9`
};
export default ___CSS_LOADER_EXPORT___;

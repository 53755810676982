// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pinCode_main__oPmUY{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;

    text-align: center;
}
.pinCode_text__GCUXu{
margin-bottom: 36px;
}
.pinCode_inlineCont__dkZjx{
    display: flex;
    justify-content: flex-end;
}
.pinCode_checkboxCont__HLtVi{
    margin-top: 62px;
    display: flex;
    align-items: baseline;
    width: 100%;
}
.pinCode_codeLink__5x9PA{
    margin-top: 22px;
    margin-bottom: 22px;
}
.pinCode_error__kZ39l{
    margin-top: 22px;
    margin-bottom: 22px;
    color: red;
    cursor:pointer;
}
.pinCode_agreementCont__br12U{
    text-align: left;
    margin-left: 15px;
}
.pinCode_agreementLinks__4IJJi{
    display: flex;
    flex-direction: column;
    margin-top: 28px;
}
.pinCode_agreementLink__lxf9D{
    color: #757575;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/modules/Registration/Components/RegPinCodeForm/pinCode.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;;IAEhB,kBAAkB;AACtB;AACA;AACA,mBAAmB;AACnB;AACA;IACI,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,qBAAqB;IACrB,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,UAAU;IACV,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":["\n.main{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-width: 360px;\n\n    text-align: center;\n}\n.text{\nmargin-bottom: 36px;\n}\n.inlineCont{\n    display: flex;\n    justify-content: flex-end;\n}\n.checkboxCont{\n    margin-top: 62px;\n    display: flex;\n    align-items: baseline;\n    width: 100%;\n}\n.codeLink{\n    margin-top: 22px;\n    margin-bottom: 22px;\n}\n.error{\n    margin-top: 22px;\n    margin-bottom: 22px;\n    color: red;\n    cursor:pointer;\n}\n.agreementCont{\n    text-align: left;\n    margin-left: 15px;\n}\n.agreementLinks{\n    display: flex;\n    flex-direction: column;\n    margin-top: 28px;\n}\n.agreementLink{\n    color: #757575;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `pinCode_main__oPmUY`,
	"text": `pinCode_text__GCUXu`,
	"inlineCont": `pinCode_inlineCont__dkZjx`,
	"checkboxCont": `pinCode_checkboxCont__HLtVi`,
	"codeLink": `pinCode_codeLink__5x9PA`,
	"error": `pinCode_error__kZ39l`,
	"agreementCont": `pinCode_agreementCont__br12U`,
	"agreementLinks": `pinCode_agreementLinks__4IJJi`,
	"agreementLink": `pinCode_agreementLink__lxf9D`
};
export default ___CSS_LOADER_EXPORT___;

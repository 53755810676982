// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_loader__QDH7Q {
    width: 50px;
    height: 50px;
    display: grid;
    animation: loader_s4__RAOBD 8s infinite;
}
.loader_loader__QDH7Q::before,
.loader_loader__QDH7Q::after {
    content:"";
    grid-area: 1/1;
    border:8px solid;
    border-radius: 50%;
    border-color:#766DF4 #766DF4 #0000 #0000;
    mix-blend-mode: darken;
    animation: loader_s4__RAOBD 2s infinite linear;
}
.loader_loader__QDH7Q::after {
    border-color:#0000 #0000 #E4E4ED #E4E4ED;
    animation-direction: reverse;
}

@keyframes loader_s4__RAOBD{
    100%{transform: rotate(1turn)}
}`, "",{"version":3,"sources":["webpack://./src/ui/Loader/loader.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uCAAyB;AAC7B;AACA;;IAEI,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,wCAAwC;IACxC,sBAAsB;IACtB,8CAAgC;AACpC;AACA;IACI,wCAAwC;IACxC,4BAA4B;AAChC;;AAEA;IACI,KAAK,wBAAwB;AACjC","sourcesContent":[".loader {\n    width: 50px;\n    height: 50px;\n    display: grid;\n    animation: s4 8s infinite;\n}\n.loader::before,\n.loader::after {\n    content:\"\";\n    grid-area: 1/1;\n    border:8px solid;\n    border-radius: 50%;\n    border-color:#766DF4 #766DF4 #0000 #0000;\n    mix-blend-mode: darken;\n    animation: s4 2s infinite linear;\n}\n.loader::after {\n    border-color:#0000 #0000 #E4E4ED #E4E4ED;\n    animation-direction: reverse;\n}\n\n@keyframes s4{\n    100%{transform: rotate(1turn)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `loader_loader__QDH7Q`,
	"s4": `loader_s4__RAOBD`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filepond--wrapper{
    padding-top: 30px;
    border:2px dashed #509ED6;
    border-radius: 4px;
    max-width: 311px;
    height: fit-content !important;
}


.filepond--panel-root {
    background-color: #D9D9D9 !important;
}
.file-uploader-text{
font-family: Roboto;
font-size: 16px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
text-align: center;
max-width: 220px;
}

.filepond--label-action{
    width: fit-content;
    margin-top: 5px !important;
    margin: auto;
padding: 0px 12px 0px 12px;
border-radius: 4px;
border: 3px solid #009FDA !important;
background-color:white;
font-family: Roboto;
font-size: 14px;
font-weight: 500;
letter-spacing: 0em;
text-align: center;
text-decoration: none !important;
color:#009FDA !important;
}
.filepond--label-action:hover{
    background-color: #F0F7FF;
}
.filepond--list-scroller{
    top:20px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Documents/components/Dropzone.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,8BAA8B;AAClC;;;AAGA;IACI,oCAAoC;AACxC;AACA;AACA,mBAAmB;AACnB,eAAe;AACf,gBAAgB;AAChB,iBAAiB;AACjB,mBAAmB;AACnB,kBAAkB;AAClB,gBAAgB;AAChB;;AAEA;IACI,kBAAkB;IAClB,0BAA0B;IAC1B,YAAY;AAChB,0BAA0B;AAC1B,kBAAkB;AAClB,oCAAoC;AACpC,sBAAsB;AACtB,mBAAmB;AACnB,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,kBAAkB;AAClB,gCAAgC;AAChC,wBAAwB;AACxB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".filepond--wrapper{\n    padding-top: 30px;\n    border:2px dashed #509ED6;\n    border-radius: 4px;\n    max-width: 311px;\n    height: fit-content !important;\n}\n\n\n.filepond--panel-root {\n    background-color: #D9D9D9 !important;\n}\n.file-uploader-text{\nfont-family: Roboto;\nfont-size: 16px;\nfont-weight: 500;\nline-height: 21px;\nletter-spacing: 0em;\ntext-align: center;\nmax-width: 220px;\n}\n\n.filepond--label-action{\n    width: fit-content;\n    margin-top: 5px !important;\n    margin: auto;\npadding: 0px 12px 0px 12px;\nborder-radius: 4px;\nborder: 3px solid #009FDA !important;\nbackground-color:white;\nfont-family: Roboto;\nfont-size: 14px;\nfont-weight: 500;\nletter-spacing: 0em;\ntext-align: center;\ntext-decoration: none !important;\ncolor:#009FDA !important;\n}\n.filepond--label-action:hover{\n    background-color: #F0F7FF;\n}\n.filepond--list-scroller{\n    top:20px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const SidebarRollIcon = (props: any) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect x="2" y="3" width="16" height="1.5" rx="0.75" fill="#565656"/>
            <rect x="2" y="7" width="16" height="1.5" rx="0.75" fill="#565656"/>
            <rect x="10" y="11" width="8" height="1.5" rx="0.75" fill="#565656"/>
            <rect x="10" y="15" width="8" height="1.5" rx="0.75" fill="#565656"/>
            <path d="M6 10.5L3 13.5L6 16.5" stroke="#565656" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    )
}

import './App.css';
import { Routes, Route, redirect, Navigate } from "react-router-dom";
import LoginPage from "./pages/Login/LoginPage";
import { Layout } from './modules/layout/Layout';
import { PrivateRoute } from './modules/PrivateRoute/PrivateRoute';
import { MedsPage } from './pages/Meds/MedCardPage';
import { DocumentsPage } from './pages/Documents/DocumentsPage';
import { ReportPage } from './pages/Reports/ReportPage';
import { useAppSelector } from './store/hooks/redux';
import { GENERAL_STATUSES } from './pages/Documents/utils';
import { MainPage } from './pages/Main/MainPage';
function App() {

const {isDocumentsValid} = useAppSelector( state => state.appReducer)
const {medic} = useAppSelector( state => state.mainReducer)

  return (
    <div className="App">
      <Routes>
        <Route path={"/"} element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }>

          {(isDocumentsValid)
           && <Route path={"/"} element={<Navigate to='/meds'/>}/>}
          <Route path={"main"} element={<MainPage/>}/>
          <Route path={"meds"} element={<MedsPage/>}/>
          <Route path={"reports/"} element={<ReportPage/>}/>
          <Route path={"reports/:curPage"} element={<ReportPage/>}/>
          <Route path={"employees"}/>
          <Route path={"shifts"}/>
          <Route path={"documents"} element={<DocumentsPage/>}/>
        </Route>
        <Route path={"/login"} element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;

import { Box, Button, Card, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, SwipeableDrawer, SxProps, Typography } from "@mui/material"
import { MedDataTable } from "./components/MedDataTable"
import { MainDataCard } from "./components/MainDataCard"
import { buttonStyle, styleCard } from "./styles/styles"
import { VideoData } from "./components/VideoData"
import { useEffect, useState } from "react"
import { useMedsPageModel } from "./MedCardPageModel"
import { DefectDrawerForm } from "./modules/Drawers/DefectDrawerForm/DefectForm"
import { DeclineDrawerForm } from "./modules/Drawers/DeclineDrawerForm/DeclineDrawerForm"
import { DriverIdentInfo } from "./components/DriverIdentInfo"
import { DriverIdentLicence } from "./components/DriverIdentLicence"
import { DriverIdentResult } from "./components/DriverIdentResult"
import { DriverIdentVideo } from "./components/DriverIdentVideo"
import { DeclineIdentDrawerForm } from "./modules/Drawers/DeclineIdentDrawerForm/DeclineIdentDrawerForm"
import { CardType } from "./types"
import { ModalNoneCards } from "./components/ModalNoneCards"
import { ModalLoadingCards } from "./components/ModalLoadingCards"
import { TimeProgressBar } from "./components/TimeProgressBar"
import { ModalDocumentProcessing } from "../../modules/layout/components/modals/ModalDocumentProcessing"
import { ModalEDSNotFound } from "../../modules/layout/components/modals/ModalEDSNotFound"


const buttonMainStyle = {

}

const buttonContainer: SxProps = {
    // position:'absolute',
    bottom: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    gap: '16px',
    backgroundColor: '#EEE',
    borderRadius: '6px',
    padding: '16px',
}


// const buttonStyle:SxProps={
//     height:'48px',
//     width:'168px',
//     borderRadius:'4px'
// }



export const MedsPage = () => {

    const model = useMedsPageModel();
    const { card, identCard } = model;
    const tableData = model.tableMedData

    return (
        <Box sx={{
            height: '889px',//minHeight: '100vh',
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            // gap: '20px',
            padding: '0px 16px 16px 16px',
        }}>

            <Box sx={{ backgroundColor: 'white' }}>
            <div style={{ zIndex: 2, margin: '0px 16px 0px 16px', borderTop: '1px solid #EEE' }} />
            </Box>

            { model.isActive && 
              model.cardType !== CardType.LOADING && 
              model.cardType !== CardType.NONE 
              ?
              <>
                <Box sx={{ 
                    width: '100%',
                    borderRadius: '0px 0px 6px 6px',
                    backgroundColor: "white", 
                    padding: '16px', position: 'relative', 
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                    marginBottom: '16px',
                }}>
                    { model.cardType === CardType.MEDICAL &&
                        <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>{
                            !!card &&
                            `${card?.type} осмотр №${card?.id} от ${card?.createdAt ? new Date(card?.createdAt.substring(0, 11)).toLocaleDateString('ru-RU') : '-'}`
                        }</Typography>
                    }
                    { model.cardType === CardType.IDENTIFICATION &&
                        <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>{
                            !!identCard &&
                            `Идентификация личности: №${identCard?.id} от ${identCard?.createdAt ? new Date(identCard?.createdAt.substring(0, 11)).toLocaleDateString('ru-RU') : '-'}`
                        }</Typography>
                    }
                    <TimeProgressBar />
                </Box>
              </>
                : null
            }

            

            { model.isActive && model.cardType === CardType.MEDICAL &&

                <Box sx={{
                    position: 'relative',
                    //margin: '20px',
                    display: 'flex',
                    gap: '16px',
                    //height: '900px'
                    height: '100%',
                }}>
                    <Stack spacing={'16px'} width={'100%'}>
                        {!!model.driverData
                         ? <MainDataCard data={model.driverData} />
                        : <></>}
                        
    
                        <MedDataTable normsData={model.normTableData} actualData={tableData} hideTitle={true} cardBorderRadius={'6px'} fontSize={'16px'} />
    
                    </Stack>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '16px',
                            width:'100%',
                            boxShadow: 'none',
                            gap: '16px',
                            borderRadius: '6px',
                            height: '100%'
                        }}
                    >
                        <VideoData
                          fullSrc={card?.full_url}
                          temperatureSrc={card?.temperature_measurement_url}
                          pressureSrc={card?.preassure_measurement_url}
                          flipping={card?.flipping}
                          presStart={card?.pressure_and_pulse_timecode_from}
                          presEnd={card?.pressure_and_pulse_timecode_to}
                          tempStart={card?.alcohol_and_temperature_from}
                          tempEnd={card?.alcohol_and_temperature_to}
                        ></VideoData>
                        <Box sx={buttonContainer}>
                            <Button onClick={ model.toggleDeclineDrawer(true) } variant={'outlined'} 
                                // sx={[buttonStyle, {width: '100%', backgroundColor: '#F0901E', 
                                //     '&:hover': {
                                //         backgroundColor: '#D0700C',
                                //     }
                                // }]} 
                                sx={{
                                  padding: '10px 12px',
                                  backgroundColor: 'white',
                                  border: '2px solid #009FDA',
                                  borderRadius: '4px',
                                  textTransform: 'none',
                                  color: '#009FDA',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  "&:hover": {
                                      backgroundColor: '#F0F7FF',
                                      border: '2px solid #009FDA',
                                      boxShadow: 'none',
                                  },
                                  boxShadow: 'none',
                                  lineHeight: '16px',
                                  width: '100%',
                              }}
                            >
                                Отклонить
                            </Button>
                            <Button onClick={ model.toggleDefectDrawer(true) } variant={'contained'} color={'error'}
                                // sx={[buttonStyle, {width: '100%', backgroundColor: '#FF4D4F', 
                                //     '&:hover': {
                                //         backgroundColor: '#DD2B2D',
                                //     }
                                // }]}
                                sx={{
                                  backgroundColor: '#FF4D4F',
                                  padding: '10px 12px',
                                  textTransform: 'none',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  "&:hover": {
                                      backgroundColor: '#DD2B2D',
                                      boxShadow: 'none',
                                  },
                                  boxShadow: 'none',
                                  lineHeight: '16px',
                                  width: '100%',
                              }}
                            >
                                Брак
                                </Button>
                            <Button onClick={() => model.approve()} variant={'contained'} 
                                // sx={[buttonStyle, {width: '100%', backgroundColor: '#33C341',
                                //     '&:hover': {
                                //         backgroundColor: '#11A120',
                                //     }
                                // }]}
                                sx={{
                                  backgroundColor: '#009FDA',
                                  padding: '10px 12px',
                                  textTransform: 'none',
                                  fontSize: '16px',
                                  fontWeight: 500,
                                  "&:hover": {
                                      backgroundColor: '#0485b5',
                                      boxShadow: 'none',
                                  },
                                  boxShadow: 'none',
                                  lineHeight: '16px',
                                  width: '100%',
                              }}
                            >
                                Допустить
                            </Button>
                        </Box>
                    </Card>
                    <DefectDrawerForm
                        open={model.isDefectOpen}
                        onOpen={model.toggleDefectDrawer(true)}
                        onClose={model.toggleDefectDrawer(false)}
                    />
                    <DeclineDrawerForm
                        open={model.isDeclineOpen}
                        onOpen={model.toggleDeclineDrawer(true)}
                        onClose={model.toggleDeclineDrawer(false)}
                    />
                </Box>

            }

            { model.isActive && model.cardType === CardType.IDENTIFICATION && 

                <Box sx={{
                    position: 'relative',
                    width:'100%',
                    //margin: '20px',
                    display: 'flex',
                    gap: '24px',
                    height: '900px',
                }}>
                    <Stack spacing={'24px'} width={'100%'}>
                        <DriverIdentInfo driver={model.identCard?.driver} />
                        <DriverIdentVideo data={model.identCard} />
                    </Stack>

                    <Stack spacing={'24px'} width={'100%'}>
                        <DriverIdentLicence data={model.identCard} />
                        <DriverIdentResult
                            approve={model.approveIdent} 
                            decline={model.toggleDeclineIdentDrawer(true)}
                        />
                    </Stack>

                    <DeclineIdentDrawerForm 
                        open={model.isDeclineIdentOpen}
                        onOpen={model.toggleDeclineIdentDrawer(true)}
                        onClose={model.toggleDeclineIdentDrawer(false)}
                    />
                </Box>
            }

            { model.isActive && model.cardType === CardType.LOADING && 
                <ModalLoadingCards />
            }

            { model.isActive && model.cardType === CardType.NONE && 
                <ModalNoneCards />
            }

        </Box>
    )


}
export const navBarStyle = {
    borderRadius: '0',
}

export const navBtnStyle = {
    "&": {
        color: '#565656', //'#EEEEEE',
        textTransform: 'none',
        justifyContent: 'flex-start',
        height: '40px',
        borderRadius: '0',
        border: 'none',
        paddingLeft: '16px',
    },
    "&:hover": {
        cursor: 'pointer',
    },
    "&.Mui-selected": {
        color: '#FFF', //'#009FDA',
        backgroundColor: '#009FDA', //'#E6F7FF',
        borderRight: '3px solid #1890FF'
    },
    "&.Mui-selected:hover": {
      color: '#FFF', //'#009FDA',
      backgroundColor: '#009FDA', //'#E6F7FF',
      borderRight: '3px solid #1890FF'
    },

    '&:disabled': {
        // color: '#EEEEEE',
        border: 'none'
    }
}

export const titleMargin = {
    marginLeft: '10px',
    transition: 'all 0.3s ease-out'
}